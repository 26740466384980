<template>
  <div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 250px)">
      <el-table-column prop="record_id" label="记录编号" width="100"/>
      <el-table-column prop="type" label="类型" width="100"/>
      <el-table-column prop="content" label="内容" show-overflow-tooltip>
        <template slot-scope="{row}">
          <a v-if="row.content_type === 2" :href="row.content" target="_blank">{{row.content}}</a>
          <pre style="margin: 0;" v-else>{{row.content}}</pre>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="记录时间" width="180"/>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: { node: Object, query: Object },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      loading: false,

      tableSort: { prop: 'id', order: 'descending' },
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    tableSort() {
      this.refresh()
    },
  },

  methods: {
    async show() {
      this.refresh();
    },
    async refresh() {
      console.log('refresh')
      this.loading = true;
      const res = await this.$api.execute('task.list_log', _.assign({ task_id: this.node.task_id }, _.pick(this, ['current', 'pageSize', 'tableSort']), this.query));
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },
  }
}
</script>