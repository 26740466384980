<template>
  <div>
    <div style="margin-bottom: 15px;">
      <el-button :disabled="!selection.length" type="primary" @click="removeSelection">删除选择</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 300px)"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column prop="id" label="记录编号" width="100" />
      <el-table-column prop="status" label="运行状态" width="80">
        <template slot-scope="{row}">
          <el-tag v-if="row.status === 1" type="success">运行中</el-tag>
          <el-tag v-else type="info">已停止</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="haoshi" label="耗时（秒）" width="100">
        <template slot-scope="{row}">
          {{row.update_time ? `${$moment(row.update_time).diff(row.create_time, 's')}` : ''}}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="开始时间" width="180" />
      <el-table-column prop="update_time" label="结束时间" width="180" />
      <el-table-column prop="options" label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="removeRecord(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: { node: Object },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      loading: false,

      tableSort: { prop: 'id', order: 'descending' },

      selection: [],
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    tableSort() {
      this.refresh()
    },
  },

  methods: {
    async show() {
      this.refresh();
    },
    async refresh() {
      console.log('refresh')
      this.loading = true;
      const res = await this.$api.execute('task.list_record', _.assign({ task_id: this.node.task_id }, _.pick(this, ['current', 'pageSize', 'tableSort'])));
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },
    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('task.remove_record', [obj.id])
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(_.includes(['close', 'cancel'], e)) { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },
    async removeSelection() {
      try {
        await this.$confirm(`此操作将永久删除（${this.selection.length}条）记录, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('task.remove_record', _.map(this.selection, 'id'))
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(_.includes(['close', 'cancel'], e)) { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },

    selectionChange(selection) {
      this.selection = selection;
    },
  }
}
</script>