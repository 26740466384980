<template>
  <div>
    <el-tabs v-model="tab" v-if="node">
      <el-tab-pane label="任务详情" name="pane1">
        <pane1 v-if="node" ref="pane1" :node="node" @refresh="refresh" />
      </el-tab-pane>
      <el-tab-pane label="执行记录" name="pane2">
        <pane2 ref="pane2" :node="node" />
      </el-tab-pane>
      <el-tab-pane label="任务日志" name="pane3">
        <pane3 ref="pane3" :node="node" />
      </el-tab-pane>
      <el-tab-pane label="采集结果" name="pane4">
        <pane3 ref="pane4" :node="node" :query="{type: '结果'}" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Pane1 from './Pane1';
import Pane2 from './Pane2';
import Pane3 from './Pane3';
export default {
  components: { Pane1, Pane2, Pane3 },
  data() {
    return {
      tab: '',
      loading: false,

      node: null,
    }
  },
  watch: {
    tab(newValue, oldValue) {
			this.$refs[oldValue]?.hide?.call();
			this.$refs[newValue]?.show?.call();
    }
  },
  async created() {
    const { tab } = this.$route.params;
    this.tab = tab || 'pane1';
		this.$store.commit('setTask');
    await this.refresh();
		this.tab !== 'pane1' && this.$refs[this.tab]?.show?.call();
  },

  methods: {
    async refresh() {
      const { id } = this.$route.params;
      try {
        this.loading = true;
        const { list: [node]} = await this.$api.execute('meta_fetch.list', { id });
        this.$store.commit('setTask', node);
        this.node = node;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },
  }
}
</script>